import Vue from 'vue'
import VueRouter from 'vue-router'

import home from '../views/home/home.vue'
import intro from '../views/intro/intro.vue'
import catalog from '../views/catalog/catalog.vue'
import service from '../views/service/service.vue'
import about from '../views/about/about.vue'
import connect from '../views/connect/connect.vue'

Vue.use(VueRouter)

const routes = [{
    path: '/',
    name: 'home',
    component: home
  },
  {
    path: '/intro',
    name: 'intro',
    component: intro
  },
  {
    path: '/catalog',
    name: 'catalog',
    component: catalog
  },
  {
    path: '/service',
    name: 'service',
    component: service
  },
  {
    path: '/about',
    name: 'about',
    component: about
  },
  {
    path: '/connect',
    name: 'connect',
    component: connect
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router