<template>
  <div class="connect">
    <div class="connect-title">联系我们</div>
    <div class="connect-content">让我们为您的企业提供增长所需的资源</div>
    <div class="connect-main">
      <div class="main-desc">
        <div class="desc-content">
          本产品适用于取得应急管理部门许可的危险化学品生产企业、经营（带有储存设施）企业、使用危险化学品从事生产的化工企业;同时，也适用于对对安全生产有数字化建设要求的其他类型企业。
        </div>
      </div>
      <div class="main-map">
        <div class="container" id="container"></div>
        <div class="map-phone">
          联系电话：+86 136-3784-9851（商务）+86 136-2849-0872（技术）
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  mounted() {
    var map = new BMapGL.Map("container");
    var point = new BMapGL.Point(106.532291, 29.59453);
    map.centerAndZoom(point, 18);
    // 创建点标记
    var marker = new BMapGL.Marker(point);
    map.addOverlay(marker);
    map.enableScrollWheelZoom(true); // 开启鼠标滚轮缩放
    // 创建信息窗口
    var opts = {
      width: 270,
      height: 50,
      title: "诏兰（重庆）智能科技有限公司",
    };
    var infoWindow = new BMapGL.InfoWindow(
      "地址：重庆市渝北区红金街2号总商会大厦7-8",
      opts
    );
    map.openInfoWindow(infoWindow, point); // 开启信息窗口
    // 点标记添加点击事件
    marker.addEventListener("click", function () {
      map.openInfoWindow(infoWindow, point); // 开启信息窗口
    });
  },
};
</script>

<style lang="scss" scoped>
.connect {
  padding: 90px;
  padding-top: 180px;
  background-image: url("./../../assets/image/7.webp");
  background-repeat: no-repeat;
  background-size: contain;
  &-title {
    font-size: 60px;
    font-weight: bold;
    line-height: 80px;
    margin-bottom: 30px;
  }
  &-content {
    width: 800px;
    font-size: 30px;
    font-weight: bold;
    margin-bottom: 100px;
  }
  &-main {
    height: 580px;
    border: 1px solid #8a8a8a4d;
    display: flex;
    background-color: #131517;
    .main-desc {
      flex: auto;
      display: flex;
      justify-content: center;
      align-items: center;
      border-right: 1px solid #8a8a8a4d;
      .desc-content {
        width: 233px;
        font-size: 16px;
        color: #8a8a8a;
      }
    }
    .main-map {
      width: 900px;
      flex: none;
      padding: 10px;
      position: relative;
      .container {
        width: 100%;
        height: 100%;
      }
      .map-phone {
        position: absolute;
        z-index: 99;
        font-size: 12px;
        background-color: #1315176d;
        bottom: 30px;
        left: 80px;
        padding-left: 10px;
      }
    }
  }
}
</style>