<template>
  <div class="catalog">
    <div class="catalog-title">产品功能目录</div>
    <div class="catalog-content">
      应用物联网、互联网等信息化技术，部署工业互联网安全生产信息化管理平台，并结合企业实际生产经营，提供整套从数据采集、存储、展示、分析、对接国家/省市平台的解决方案，切实的为企业提供基于数据的运营优化提升、降本增效，使企业信息化管理平稳、安全运行
    </div>
    <div class="catalog-main">
      <div class="main-item">
        <div class="item-title">移动端</div>
        <div class="item-content">
          <el-image
            class="content-img"
            :src="require('./../../assets/image/小程序端.png')"
            :preview-src-list="[require('./../../assets/image/小程序端.png')]"
          ></el-image>
        </div>
      </div>
      <div class="main-item">
        <div class="item-content">
          <el-image
            class="content-img"
            :src="require('./../../assets/image/后台管理端.png')"
            :preview-src-list="[require('./../../assets/image/后台管理端.png')]"
          ></el-image>
        </div>
        <div class="item-title">后台管理端</div>
      </div>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped>
.catalog {
  min-height: 2000px;
  padding: 90px;
  padding-top: 180px;
  background-image: url("./../../assets/image/7.webp");
  background-repeat: no-repeat;
  background-size: contain;
  &-title {
    font-size: 60px;
    font-weight: bold;
    line-height: 80px;
    margin-bottom: 30px;
  }
  &-content {
    width: 800px;
    font-size: 30px;
    font-weight: bold;
    margin-bottom: 100px;
  }
  &-main {
    border: 1px solid #8a8a8a4d;
    background-color: #131517;
    .main-item {
      height: 630px;
      display: flex;
      .item-title {
        width: 540px;
        flex: none;
        background-image: url("./../../assets/image/10.webp");
        background-repeat: no-repeat;
        background-size: cover;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 30px;
        font-weight: bold;
      }
      .item-content {
        flex: auto;
        display: flex;
        justify-content: center;
        align-items: center;
        .content-img {
          width: 400px;
          height: 500px;
        }
      }
      &:first-child {
        .item-title {
          border-bottom: 1px solid #8a8a8a4d;
          border-right: 1px solid #8a8a8a4d;
        }
        .item-content {
          border-bottom: 1px solid #8a8a8a4d;
        }
      }
      &:last-child {
        .item-title {
          background-image: url("./../../assets/image/11.webp");
        }
        .item-content {
          border-right: 1px solid #8a8a8a4d;
        }
      }
    }
  }
}
</style>