<template>
  <div class="basebar">
    <div class="basebar-connect">
      <div class="connect-logo">诏兰</div>
      <div class="connect-item">+86 136-3784-9851（商务）</div>
      <div class="connect-item">+86 136-2849-0872（技术）</div>
      <div class="connect-item">welcome@zhaolantech.com</div>
      <div class="connect-item">重庆市渝北区红金街2号总商会大厦7-8</div>
    </div>
    <div class="basebar-menu">
      <div
        class="menu-item"
        :class="{ 'menu-item-active': activeRoute === item.route }"
        v-for="item in menuItems"
        :key="item.route"
        @click="goToRoute(item.route)"
      >
        {{ item.label }}
      </div>
    </div>
    <!-- <div class="basebar-subscribe">
      <div class="subscribe-title">订阅我们</div>
      <div class="subscribe-desc">Email*</div>
      <el-input
        class="subscribe-input"
        v-model="emailValue"
        size="mini"
        clearable
      />
      <div class="subscribe-button">提交</div>
    </div> -->
    <div class="basebar-intro">
      <div class="intro-title">关注微信公众号：</div>
      <img src="./../assets/image/code.jpeg" class="intro-img" />
      <div class="intro-desc">© 2023 诏兰（重庆）<br />智能科技有限公司</div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      menuItems: [
        { label: "平台简介", route: "/intro" },
        { label: "功能目录", route: "/catalog" },
        { label: "其他服务", route: "/service" },
        // { label: "关于我们", route: "/about" },
        { label: "联系我们", route: "/connect" },
      ],
      emailValue: "",
    };
  },
  computed: {
    activeRoute() {
      return this.$store.state.activeRoute;
    },
  },
  methods: {
    goToRoute(route) {
      this.$store.commit("goToRoute", route);
    },
  },
};
</script>

<style lang="scss" scoped>
.basebar {
  display: flex;
  justify-content: space-between;
  font-size: 18px;
  line-height: 54px;
  color: #8a8a8a;
  &-connect {
    .connect-logo {
      font-size: 24px;
      margin-bottom: 20px;
      font-weight: bold;
      color: #fff;
    }
    .connect-item {
      line-height: 36px;
    }
  }
  &-menu {
    .menu-item {
      cursor: pointer;
      &:last-child {
        width: 121px;
        height: 55px;
        background-color: #fd6262;
        color: #131517;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 5px;
        margin-top: 20px;
        transition: all 0.5s;
        border: 1px solid #fd6262;
        font-size: 16px;
        &:hover {
          color: #fff;
          border: 1px solid #fff;
          background-color: transparent;
        }
      }
    }
  }
  &-subscribe {
    .subscribe-desc {
      font-size: 14px;
      line-height: 30px;
    }
    .subscribe-button {
      width: 115px;
      height: 55px;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 5px;
      margin-top: 20px;
      transition: all 0.5s;
      border: 1px solid #8a8a8a;
      font-size: 16px;
      cursor: pointer;
      &:hover {
        color: #131517;
        border: 1px solid #fd6262;
        background-color: #fd6262;
      }
    }
  }
  &-intro {
    .intro-img {
      width: 120px;
      height: 120px;
    }
    .intro-desc {
      font-size: 14px;
      line-height: 20px;
    }
  }
}
</style>
<style lang="scss">
.subscribe-input {
  width: 283px !important;
  height: 55px !important;
  input {
    width: 100% !important;
    height: 100% !important;
    background-color: transparent !important;
    font-size: 16px !important;
    border-color: #fd6262 !important;
    outline: none !important;
  }
}
</style>