<template>
  <div class="service">
    <div class="service-title">围绕数字化领域</div>
    <div class="service-content">
      我们提供数字孪生产品设计、数字化解决方案设计、信息化产品开发服务
    </div>
    <div class="service-main">
      <div class="main-item" v-for="item in serviceList" :key="item.name">
        <img
          class="item-img"
          :src="require(`./../../assets/image/${item.img}.webp`)"
        />
        <div class="item-content">
          <div class="content-name">{{ item.name }}</div>
          <div class="content-desc">{{ item.desc }}</div>
          <div class="content-line"></div>
          <div class="content-price">¥{{ item.price }} 起</div>
          <div class="content-button" @click="goToRoute('/connect')">
            立即咨询
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      serviceList: [
        {
          img: "12",
          name: "数字孪生产品设计",
          desc: "远程+驻场服务",
          price: 0,
        },
        {
          img: "13",
          name: "数字化解决方案设计",
          desc: "远程+驻场服务",
          price: 0,
        },
        {
          img: "14",
          name: "信息化产品开发服务",
          desc: "远程+驻场服务",
          price: 0,
        },
      ],
    };
  },
  methods: {
    goToRoute(route) {
      this.$store.commit("goToRoute", route);
    },
  },
};
</script>
<style lang="scss" scoped>
.service {
  padding: 90px;
  padding-top: 180px;
  &-title {
    font-size: 60px;
    font-weight: bold;
    line-height: 80px;
    margin-bottom: 30px;
  }
  &-content {
    width: 800px;
    font-size: 30px;
    font-weight: bold;
    margin-bottom: 300px;
  }
  &-main {
    border: 1px solid #8a8a8a4d;
    height: 600px;
    display: flex;
    .main-item {
      flex: auto;
      &:nth-child(2) {
        border-left: 1px solid #8a8a8a4d;
        border-right: 1px solid #8a8a8a4d;
      }
      .item-img {
        width: 100%;
        height: 300px;
        vertical-align: middle;
      }
      .item-content {
        border-top: 1px solid #fd626299;
        height: 300px;
        padding: 32px 50px;
        display: flex;
        flex-flow: column nowrap;
        justify-content: space-between;
        font-size: 16px;
        .content-name {
          font-size: 21px;
        }
        .content-line {
          width: 40px;
          height: 1px;
          background-color: #ffffff4d;
        }
        .content-button {
          width: 134px;
          height: 38px;
          border-radius: 5px;
          background-color: #fd6262;
          color: #131517;
          border: 1px solid #fd6262;
          font-size: 16px;
          display: flex;
          justify-content: center;
          align-items: center;
          transition: all 0.5s;
          cursor: pointer;
          &:hover {
            color: #fff;
            border: 1px solid #fff;
            background-color: transparent;
          }
        }
      }
    }
  }
}
</style>