<template>
  <div class="home">
    <transition appear name="main">
      <div class="home-main">
        <div class="main-title">工业互联网安全生产<br />信息化管理平台</div>
        <div class="main-desc">
          落实企业安全生产主体责任，提升企业安全生产管理水平，筑牢企业安全生产安全防线
        </div>
        <div class="main-button" @click="goToRoute('/intro')">了解平台</div>
      </div>
    </transition>
    <div class="home-assistant">
      <div class="assistant-main">
        <div class="main-title">助理企业实现数字化转型</div>
        <div class="main-desc">
          以安全生产全要素数字化管理为目标，围绕风险分级管控和隐患排查、安全管理、安全生产标准化等内容，建设集安全风险分级管控和隐患排查治理、重大危险源管理、安全生产全流程管理信息等于-体的安全生产信息化管理平台，切实落实企业安全生产主体责任，全面提升企业安全生产管理水平。
        </div>
      </div>
      <div class="assistant-content">
        <div
          class="content-item"
          v-for="item in assistantList"
          :key="item.title"
        >
          <i :class="['item-icon', item.icon]"></i>
          <div class="item-title">{{ item.title }}</div>
          <div class="item-content">
            {{ item.content }}
          </div>
          <div class="item-line"></div>
        </div>
      </div>
    </div>
    <div class="home-construction">
      <div class="construction-main">
        功能建设要直观易用，更要符合<br />政策、符合企业的实际经营情况
      </div>
      <div class="construction-content">
        <div class="content-item">
          移动端：如何更好的为领导提供管理企业安全、高效运行的数据决策支撑？为主管提供管理企业员工、设备、任务等的工具？为员工提供安全技能学习、培训、任务认领与结办的窗口？是安全生产信息化管理移动端功能建设的目标。
        </div>
        <div class="content-item">
          后台管理端：如何结合政策要求，实现双重预防机制系统、特殊作业管理系统、安全人员在岗在位、智能视频监控系统、电子巡检、教育培训、安全生产全流程管理等板块的功能？如何设计风险管控看板、隐患管控看板、作业台账看板，提供直观的数据决策支撑？如何实现将企业的3D地图与动态风险四色图、视频智能监控、实时数据监测、特殊作业、人员在岗在位、厂区人数、风险信息、隐患治理、企业承诺、安全数据监测、本月隐患排查计划、本月作业票统计、全屏展示、天气展示等信息融合为安全生产一张图？是后台管理端功能建设的目标。
        </div>
        <div class="content-button" @click="goToRoute('/catalog')">
          了解功能目录
        </div>
      </div>
    </div>
    <div class="home-info">
      <div class="info-title">平台信息</div>
      <div class="info-content">
        <div class="content-item" v-for="item in infoList" :key="item.name">
          <div class="item-value">{{ item.value }}</div>
          <div class="item-name">{{ item.name }}</div>
        </div>
      </div>
    </div>
    <div class="home-img"></div>
    <div class="home-build">
      <div class="build-main">
        <img class="main-img" src="./../../assets/image/6.webp" alt="" />
        <div class="main-content">构建您企业的安全生产信息化<br />管理平台</div>
      </div>
      <div class="build-content">
        <div class="content-desc">
          本产品适用于取得应急管理部门许可的危险化学品生产企业、经营(带有储存设施)企业、使用危险化学品从事生产的化工企业；同时，也适用于对对安全生产有数字化建设要求的其他类型企业。
        </div>
        <div class="content-button" @click="goToRoute('/connect')">
          联系我们
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      assistantList: [
        {
          icon: "el-icon-upload",
          title: "双重预防机制",
          content:
            "按照应急管理部《危险化学品企业双重预防机制数字化建设工作方案》等政策，开展安全风险分级管控和隐患排查治理双重预防机制数字化建设。",
        },
        {
          icon: "el-icon-s-data",
          title: "特殊作业管理",
          content:
            "按照《危险化学品企业特殊作业安全规范》等政策，建立企业的特殊作业管理全流程，管理每种作业存在的各种危害的安全措施，降低作业风险。",
        },
        {
          icon: "el-icon-s-grid",
          title: "安全生产全流程管理",
          content:
            "按照《中华人民共和国安全生产法》、《危险化学品安全管理条例》等政策，加强企业应急、危化品、安全事故、安全联锁、目标责任等的管理。",
        },
        {
          icon: "el-icon-s-custom",
          title: "危险源监测预警",
          content:
            "按照《危险化学品重大危险源辨识》等政策，建立企业重大危险源管理、可燃有毒气体监测与预警系统，切实提高企业对危险源的监测、预警能力。",
        },
      ],
      infoList: [
        {
          value: "15+",
          name: "合作客户",
        },
        {
          value: "2+",
          name: "2年无运行故障",
        },
        {
          value: "2",
          name: "私有、公网部署",
        },
        {
          value: "10",
          name: "10个子系统",
        },
      ],
    };
  },
  methods: {
    goToRoute(route) {
      this.$store.commit("goToRoute", route);
    },
  },
};
</script>

<style lang="scss" scoped>
.home {
  padding-top: 90px;
  background-image: url("./../../assets/image/1.webp");
  background-size: contain;
  background-repeat: no-repeat;
  &-main {
    padding: 0 90px;
    margin-top: 50px;
    .main-title {
      font-size: 80px;
      font-weight: bold;
      line-height: 100px;
    }
    .main-desc {
      width: 460px;
      font-size: 30px;
      margin: 30px 0;
    }
    .main-button {
      width: 142px;
      height: 55px;
      border-radius: 5px;
      background-color: #fd6262;
      color: #131517;
      border: 1px solid #fd6262;
      font-size: 16px;
      display: flex;
      justify-content: center;
      align-items: center;
      transition: all 0.5s;
      cursor: pointer;
      &:hover {
        color: #fff;
        border: 1px solid #fff;
        background-color: transparent;
      }
    }
  }
  &-assistant {
    margin-top: 600px;
    padding: 0 90px;
    height: 700px;
    display: flex;
    flex-flow: row nowrap;
    .assistant-main {
      flex: auto;
      border: 1px solid #8a8a8a4d;
      border-right: none;
      background-image: url("./../../assets/image/2.webp");
      background-repeat: no-repeat;
      background-size: cover;
      display: flex;
      flex-flow: column nowrap;
      justify-content: center;
      align-items: center;
      .main-title {
        font-size: 30px;
        font-weight: bold;
      }
      .main-desc {
        font-size: 16px;
        color: #8a8a8a;
        max-width: 300px;
        margin-top: 20px;
      }
    }
    .assistant-content {
      width: 800px;
      flex: none;
      border: 1px solid #8a8a8a4d;
      background-color: #131517;
      position: relative;
      display: flex;
      justify-content: center;
      .content-item {
        width: 240px;
        height: 350px;
        background-color: #1b1c1e;
        border-radius: 10px;
        position: absolute;
        padding: 30px;
        display: flex;
        flex-flow: column nowrap;
        justify-content: space-between;
        .item-icon {
          color: #fd6262;
          font-size: 40px;
        }
        .item-title {
          font-size: 18px;
          color: #889ce7;
          font-weight: bold;
        }
        .item-content {
          font-size: 14px;
          color: #8a8a8a;
        }
        .item-line {
          height: 4px;
          background-color: #3f4349;
        }
        &:nth-child(1) {
          top: -100px;
          left: 150px;
        }
        &:nth-child(2) {
          top: 270px;
          left: 150px;
        }
        &:nth-child(3) {
          bottom: 270px;
          right: 150px;
        }
        &:nth-child(4) {
          bottom: -100px;
          right: 150px;
        }
      }
    }
  }
  &-construction {
    margin-top: 200px;
    height: 800px;
    padding: 0 90px;
    display: flex;
    .construction-main {
      background-image: url("./../../assets/image/3.webp");
      background-repeat: no-repeat;
      background-size: cover;
      width: 50%;
      font-size: 30px;
      font-weight: bold;
      display: flex;
      justify-content: center;
      align-items: center;
      border: 1px solid #8a8a8a4d;
      border-right: none;
    }
    .construction-content {
      width: 50%;
      border: 1px solid #8a8a8a4d;
      display: flex;
      flex-flow: column nowrap;
      align-items: center;
      justify-content: center;
      .content-item {
        width: 350px;
        font-size: 16px;
        color: #8a8a8a;
        margin-bottom: 40px;
      }
      .content-button {
        width: 142px;
        height: 55px;
        background-color: #fd6262;
        border: 1px solid #fd6262;
        color: #131517;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 5px;
        cursor: pointer;
        transition: all 0.5s;
        &:hover {
          background-color: transparent;
          color: #fff;
          border: 1px solid #fff;
        }
      }
    }
  }
  &-info {
    padding: 0 90px;
    margin-top: 50px;
    .info-title {
      text-align: center;
      font-size: 30px;
      line-height: 100px;
      font-weight: bold;
    }
    .info-content {
      display: flex;
      justify-content: space-evenly;
      .content-item {
        width: 150px;
        height: 150px;
        display: flex;
        flex-flow: column nowrap;
        align-items: center;
        justify-content: center;
        border-bottom: 4px solid #3f4349;
        .item-value {
          font-size: 60px;
          line-height: 90px;
          color: #fd6262;
          font-weight: bold;
        }
        .item-name {
          font-size: 14px;
          color: #8a8a8a;
        }
      }
    }
  }
  &-img {
    width: 100%;
    height: 300px;
    background-image: url("./../../assets/image/4.webp");
    background-repeat: no-repeat;
    background-position: center 70%;
    margin-top: 100px;
  }
  &-build {
    margin: 100px 0;
    height: 700px;
    padding: 0 90px;
    display: flex;
    .build-main {
      width: 800px;
      flex: none;
      background-image: url("./../../assets/image/5.webp");
      background-repeat: no-repeat;
      background-size: cover;
      display: flex;
      justify-content: center;
      align-items: center;
      border: 1px solid #8a8a8a4d;
      border-right: none;
      .main-img {
        width: 500px;
        height: 500px;
      }
      .main-content {
        position: absolute;
        text-align: center;
        font-size: 30px;
        font-weight: bold;
      }
    }
    .build-content {
      flex: auto;
      border: 1px solid #8a8a8a4d;
      display: flex;
      flex-flow: column nowrap;
      justify-content: center;
      align-items: center;
      .content-desc {
        width: 289px;
        font-size: 16px;
        color: #8a8a8a;
      }
      .content-button {
        margin-top: 30px;
        width: 142px;
        height: 55px;
        background-color: #fd6262;
        border: 1px solid #fd6262;
        transition: all 0.5s;
        cursor: pointer;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 5px;
        color: #131517;
        &:hover {
          background-color: transparent;
          color: #fff;
          border: 1px solid #fff;
        }
      }
    }
  }
}

.main-enter-active {
  transition: all 2s;
}
.main-enter {
  opacity: 0;
  transform: translateX(-100px);
}
</style>