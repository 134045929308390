import Vue from 'vue'
import Vuex from 'vuex'
import Router from './../router'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    activeRoute: '/'
  },
  getters: {},
  mutations: {
    goToRoute(state, route) {
      state.activeRoute = route
      Router.push({
        path: route
      })
    }
  },
  actions: {},
  modules: {}
})