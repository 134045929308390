<template>
  <div class="intro">
    <div class="intro-title">工业互联网信息化<br />安全生产信息化管理平台</div>
    <div class="intro-desc">我们的服务</div>
    <div class="intro-content">
      <div class="content-item" v-for="item in introList" :key="item.title">
        <div class="item-title">
          <div class="title-icon">
            <i :class="[item.icon, 'icon-content']"></i>
            <div class="icon-line"></div>
          </div>
        </div>
        <div class="item-main">
          <div class="main-title">{{ item.title }}</div>
          <div class="main-content">{{ item.content }}</div>
        </div>
      </div>
      <div class="content-desc">
        <div class="desc-title">产品功能模块设计</div>
        <div class="desc-main">
          <el-image
            class="main-img"
            :src="require('./../../assets/image/两张图1.png')"
            :preview-src-list="[require('./../../assets/image/两张图1.png')]"
          ></el-image>
          <el-image
            class="main-img"
            :src="require('./../../assets/image/两张图2.png')"
            :preview-src-list="[require('./../../assets/image/两张图2.png')]"
          ></el-image>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      introList: [
        {
          icon: "el-icon-upload",
          title: "双重预防机制",
          content:
            "按照应急管理部《危险化学品企业双重预防机制数字化建设工作方案》等政策，开展安全风险分级管控和隐患排查治理双重预防机制数字化建设。",
        },
        {
          icon: "el-icon-s-data",
          title: "特殊作业管理",
          content:
            "按照《危险化学品企业特殊作业安全规范》等政策，建立企业的特殊作业管理全流程，管理每种作业存在的各种危害的安全措施，降低作业风险。",
        },
        {
          icon: "el-icon-s-grid",
          title: "安全生产全流程管理",
          content:
            "按照《中华人民共和国安全生产法》、《危险化学品安全管理条例》等政策，加强企业应急、危化品、安全事故、安全联锁、目标责任等的管理。",
        },
        {
          icon: "el-icon-s-custom",
          title: "危险源监测预警",
          content:
            "按照《危险化学品重大危险源辨识》等政策，建立企业重大危险源管理、可燃有毒气体监测与预警系统，切实提高企业对危险源的监测、预警能力。",
        },
      ],
    };
  },
};
</script>

<style lang="scss" scoped>
.intro {
  padding: 90px;
    padding-top: 180px;
  background-image: url("./../../assets/image/7.webp");
  background-repeat: no-repeat;
  background-size: contain;
  &-title {
    font-size: 60px;
    font-weight: bold;
    line-height: 80px;
    margin-bottom: 60px;
  }
  &-desc {
    font-size: 30px;
    font-weight: bold;
    margin-bottom: 20px;
  }
  &-content {
    border: 1px solid #8a8a8a4d;
    background-color: #131517;
    .content-item {
      display: flex;
      .item-title {
        width: 270px;
        height: 230px;
        flex: none;
        border-bottom: 1px solid #8a8a8a4d;
        border-right: 1px solid #8a8a8a4d;
        display: flex;
        justify-content: center;
        align-items: center;
        .title-icon {
          width: 95px;
          height: 95px;
          border-radius: 10px;
          background-color: #889ce7;
          display: flex;
          flex-flow: column nowrap;
          align-items: center;
          justify-content: space-evenly;
          .icon-content {
            font-size: 50px;
            color: #131517;
          }
          .icon-line {
            width: 50px;
            height: 4px;
            background-color: #131517;
          }
        }
      }
      &:nth-child(2),
      &:nth-child(4) {
        .item-title {
          .title-icon {
            background-color: #fd6262;
          }
        }
      }
      .item-main {
        flex: auto;
        border-bottom: 1px solid #8a8a8a4d;
        display: flex;
        flex-flow: column nowrap;
        justify-content: center;
        padding-left: 90px;
        .main-title {
          font-size: 18px;
          margin-bottom: 20px;
        }
        .main-content {
          font-size: 16px;
          color: #8a8a8a;
          width: 650px;
        }
      }
    }
    .content-desc {
      display: flex;
      height: 470px;
      .desc-title {
        width: 540px;
        height: 100%;
        flex: none;
        background-image: url("./../../assets/image/8.webp");
        background-repeat: no-repeat;
        background-size: cover;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 30px;
        font-weight: bold;
        border-right: 1px solid #8a8a8a4d;
      }
      .desc-main {
        flex: auto;
        display: flex;
        flex-flow: column nowrap;
        justify-content: space-evenly;
        align-items: center;
        .main-img {
          width: 500px;
          height: 200px;
        }
      }
    }
  }
}
</style>