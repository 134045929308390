<template>
  <div class="icp">
    <img class="icp-icon" src="./../assets/image/icp.png" />
    <div class="icp-item" @click="goToIcp(true)">渝公网安备2022013690号</div>
    <div class="icp-item" @click="goToIcp(false)">渝ICP备2022013690号-1</div>
    <a
      href="https://xyt.xcc.cn/getpcInfo?sn=1613132690712666112&language=CN&certType=8&url=*.zhaolantech.com"
      target="_blank"
    ></a>
  </div>
</template>

<script>
export default {
  methods: {
    goToIcp(val) {
      val
        ? window.open(
            "http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=2022013690"
          )
        : window.open(
            "https://beian.miit.gov.cn/state/outPortal/loginPortal.action#/Integrated/index"
          );
    },
  },
};
</script>

<style lang="scss" scoped>
.icp {
  display: flex;
  justify-content: center;
  align-items: center;
  border-top: 1px solid #8a8a8a4d;
  &-icon {
    width: 20px;
    height: 20px;
  }
  &-item {
    font-size: 12px;
    text-align: center;
    color: #8a8a8a;
    line-height: 40px;
    margin-left: 5px;
    cursor: pointer;
    &:hover {
      color: #fd6262;
    }
  }
}
</style>