<template>
  <div id="app" class="app">
    <div
      :class="[
        'navbar-wrapper',
        isShowNavbarBackGround ? 'navbar-wrapper-background' : '',
      ]"
      v-if="isShowNavbar"
    >
      <NavBar />
    </div>
    <transition appear name="router" mode="out-in">
      <router-view />
    </transition>
    <div class="basebar-wrapper">
      <BaseBar />
    </div>
    <div class="icp-wrapper">
      <Icp />
    </div>
  </div>
</template>

<script>
import NavBar from "@/components/NavBar";
import BaseBar from "@/components/BaseBar";
import Icp from "@/components/Icp";

export default {
  components: { NavBar, BaseBar, Icp },
  data() {
    return {
      isShowNavbarBackGround: false,
      isShowNavbar: true,
      backGroundImg: "./assets/image/1.webp",
    };
  },
  created() {
    this.$store.commit("goToRoute", "/");
  },
  mounted() {
    window.addEventListener("scroll", () => {
      if (document.documentElement.scrollTop >= 100) {
        this.isShowNavbarBackGround = true;
      } else {
        this.isShowNavbarBackGround = false;
      }
      if (document.documentElement.scrollTop >= 500) {
        this.isShowNavbar = false;
      } else {
        this.isShowNavbar = true;
      }
    });
  },
};
</script>

<style lang="scss">
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

html {
  background-color: #131517;
  color: #fff;
  font: 16px/1.7 Helvetica Neue, Helvetica, PingFang SC, Hiragino Sans GB,
    Microsoft YaHei, Arial, sans-serif, sans-serif;
}

.app {
  width: 100%;
  min-width: 1200px;
  .navbar-wrapper {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 999;
    width: 100%;
    min-width: 1200px;
    height: 90px;
    background-color: transparent;
    transition: background-color 1s;
  }
  .navbar-wrapper-background {
    background-color: #131517;
  }
  .basebar-wrapper {
    padding: 90px 90px 30px;
  }
  .icp-wrapper {
  }
}

.router-enter-active,
.router-leave-active {
  transition: opacity 0.5s;
}
.router-enter,
.router-leave-to {
  opacity: 0;
}
</style>