<template>
  <div class="navbar">
    <div class="navbar-logo" @click="goToRoute('/')">诏兰</div>
    <div class="navbar-menu">
      <div
        class="menu-item"
        :class="{ 'menu-item-active': activeRoute === item.route }"
        v-for="item in menuItems"
        :key="item.route"
        @click="goToRoute(item.route)"
      >
        {{ item.label }}
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      menuItems: [
        { label: "平台简介", route: "/intro" },
        { label: "功能目录", route: "/catalog" },
        { label: "其他服务", route: "/service" },
        // { label: "关于我们", route: "/about" },
        { label: "联系我们", route: "/connect" },
      ],
    };
  },
  computed: {
    activeRoute() {
      return this.$store.state.activeRoute;
    },
  },
  methods: {
    goToRoute(route) {
      this.$store.commit("goToRoute", route);
    },
  },
};
</script>

<style lang="scss" scoped>
.navbar {
  width: 100%;
  height: 100%;
  padding: 0 90px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: #fff;
  &-logo {
    font-size: 24px;
    font-weight: bold;
    cursor: pointer;
  }
  &-menu {
    height: 100%;
    display: flex;
    align-items: center;
    .menu-item {
      width: 90px;
      height: 45px;
      font-size: 14px;
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;
      transition: 0.5s all;
      &:hover {
        color: #fd6262;
      }
      &:last-child {
        width: 105px;
        border: 1px solid #fff;
        border-radius: 5px;
        margin-left: 10px;
        &:hover {
          background-color: #fd6262;
          color: #131517;
          border: 1px solid #fd6262;
        }
        &.menu-item-active {
          background-color: #fd6262;
          color: #131517;
          border: 1px solid #fd6262;
        }
      }
      &.menu-item-active {
        color: #fd6262;
      }
    }
  }
}
</style>